import { inject, Injectable } from '@angular/core';
import { AppConfig } from './app-config';
import { HostApi } from './host-api.service';
import { environment } from '@tytapp/environment';
import { LoggerService } from './logger.service';

/**
 * Provides application versioning logic. This is the core of several features.
 *
 * > IMPORTANT: The Battlecruiser apps have a similar system with conflicting names, but differing values.
 * > It is important that version constraints destined for the Native iOS and Native Android apps use the
 * > app names "ios_web" and "android_web" respectively (instead of "ios" and "android").
 * > Battlecruiser uses "min_version", "recommended_version", and "strongly_recommended_version", and they are
 * > marketing versions (X.Y.Z) instead of build numbers.
 * ### Server-Declared Minimum Versions
 *
 * The server can send app setting `min_version` to control the "minimum version" of the application. The meaning
 * of this depends on the SKU of TYT.com being run.
 *
 * - On web builds (environment.isNativeBuild === false), instances running versions older than the minimum will
 *   attempt to uninstall their service workers, which should fix broken browsers that refuse to allow TYT.com
 *   to update. If that fails, the user is presented with a prompt asking them to clear the site data for TYT.com and
 *   links them to a help article (currently https://help.tyt.com/article/155-clear-cache-by-website)
 *
 * - On native builds (environment.isNativeBuild === true), the user is presented with a message indicating they
 *   must update, and a link to the appropriate app store location.
 *
 * ### Server-Declared Preferred Versions
 *
 * The server can send app setting `preferred_version` to control the "preferred version" of the application.
 *
 * - On web builds the value is ignored
 * - On native builds, a dialog is shown asking them to update and linking them to the appropriate store. The dialog
 *   can be dismissed.
 *
 */
@Injectable()
export class VersionService {
    private appConfig = inject(AppConfig);
    private hostApi = inject(HostApi);
    private logger = inject(LoggerService).configure({ source: 'version' });

    readonly ready = Promise.all([this.hostApi.capabilitiesReady, this.appConfig.appStatusReady]);

    get min() {
        return String(this.appConfig?.appStatus?.settings?.min_version ?? 0);
    }

    get preferred() {
        return String(this.appConfig?.appStatus?.settings?.preferred_version ?? 0);
    }

    get current() {
        return String(this.hostApi.capabilitiesMessage?.version ?? environment.version);
    }

    compare(a: string, b: string) {
        if (isNaN(parseInt(a)) || isNaN(parseInt(b))) {
            this.logger.warning(`Cannot compare versions '${a}' and '${b}' -- Only numeric comparisons are supported!`);
            return 0;
        }
        return parseInt(a) - parseInt(b);
    }

    get preferredUpdateAvailable() { return this.compare(this.preferred, this.current) > 0; }
    get isOutdated() { return true || this.compare(this.min, this.current) > 0; }
}