import { Component, ElementRef, inject, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { HostApi, isMessageOfType, Message, NavigateMessage, PlatformCapabilitiesMessage, Request, Response } from '../host-api.service';
import { environment } from '@tytapp/environment';
import { isClientSide, rewriteUrl } from '@tytapp/environment-utils';
import { LoggerService } from '../logger.service';
import { v4 as uuid } from 'uuid';
import { filter, Subject, take } from 'rxjs';
import { VersionService } from '../version.service';
import { Router } from '@angular/router';

/**
 * Provides the ability to host a TYT.com instance within an iframe and control it.
 * See HostApi for more details. While native apps don't use this for the top level hosting,
 * this is used when the TYT.com instance itself needs to host another TYT.com instance (see Page View for
 * example).
 */
@Component({
    selector: 'tyt-host',
    templateUrl: './host.component.html',
    styleUrl: './host.component.scss'
})
export class HostComponent {
    private hostApi = inject(HostApi);
    private logger = inject(LoggerService).configure({ source: 'host', includeDate: false, includeRequest: false });
    private version = inject(VersionService);
    private router = inject(Router);

    ngOnInit() {
        this.messageReceived.subscribe(message => {
            if (this.consumeNavigation) {
                if (isMessageOfType<NavigateMessage>(message, 'navigate')) {
                    this.router.navigateByUrl(message.url);
                }
            }
        });
    }

    ngAfterViewInit() {
        window.addEventListener('message', this.messageListener);
        this.markIframeReady();
    }

    ngOnDestroy() {
        window.removeEventListener('message', this.messageListener);
    }

    private _url: string;
    private _origin = environment.name === 'development' && isClientSide() ? location.origin : environment.urls.webRoot;

    get devTools() { return environment.showDevTools; }

    @ViewChild('frame', { read: ElementRef })
    iframeRef: ElementRef<HTMLIFrameElement>;

    get iframe() {
        return this.iframeRef?.nativeElement;
    }

    /**
     * When true, the host component will automatically intercept navigations which occur in the inner frame and
     * apply them in this application instance instead.
     */
    @Input()
    consumeNavigation = false;

    @Input()
    get origin() {
        return this._origin;
    }

    set origin(value) {
        if (this._origin === value)
            return;

        this._origin = value;
    }

    get url() {
        return this.iframe?.src;
    }

    @Input()
    get path() {
        return this._url;
    }

    set path(value) {
        if (this._url === value)
            return;

        this._url = value;
        setTimeout(() => {

        });
    }

    @Input()
    useHostCapabilities = true;

    @Input()
    capabilities: string[];

    @Input()
    instanceName = 'child';
    instanceId = uuid();

    get frameUrl() {
        let baseUrl = environment.urls.webRoot;

        // Used to make it easier to test the integration
        if (environment.name === 'development' && isClientSide()) {
            baseUrl = location.origin;
        }

        let url = new URL(`${this.origin}${this.path}`);
        url.searchParams.set('chromeless', '1');
        url.searchParams.set('transparentBg', '1');
        url.searchParams.set('skipFrame', '1');
        url.searchParams.set('hosted', '1');
        url.searchParams.set('instanceId', this.instanceId);
        url.searchParams.set('instanceName', this.instanceName);
        url.searchParams.set('persistHosting', '0');

        return url.toString();
    }

    async sendMessage<MessageT extends Message>(message: MessageT) {
        await this.ready;
        this.logger.info(`Sending message, type: ${message.type}`, message);
        this.iframe.contentWindow.postMessage(JSON.stringify(message));
    }

    private fireReady: () => void;
    ready = new Promise<void>(r => this.fireReady = r);

    async sendRequest<Req extends Request, Res extends Response>(requestInit: Omit<Req, 'request_id'>): Promise<Res> {
        await this.ready;
        return new Promise<Res>((resolve, reject) => {
            let requestId = uuid();
            this.messageReceived
                .pipe(filter((r: Res) => r.type === 'result' && r.request_id === requestId))
                .pipe(take(1))
                .subscribe(r => {
                    r.error_message ? reject(new Error(r.error_message)) : resolve(<Res>r);
                });
            this.sendMessage({ ...requestInit, request_id: requestId });
        });
    }

    private _messageReceived = new Subject<Message>();

    @Output()
    get messageReceived() { return this._messageReceived.asObservable(); }

    messageListener = async (ev: MessageEvent) => {
        if (ev.data.startsWith('[iFrame'))
            return;

        let message: Message;
        try {
            message = JSON.parse(ev.data);
        } catch (e) {
            this.logger.error(`Failed to parse message from child instance:`);
            this.logger.error(e);
            return;
        }

        if (!message.type) {
            this.logger.warning(`Received invalid message with no type: ${JSON.stringify(message)}`);
            return;
        }

        this.logger.info(`Received Message, type: ${message.type}`, message);

        if (message.type === 'ready') {
            await this.iframeReady;
            this.fireReady();

            let capabilities: string[] = [];
            if (this.useHostCapabilities && this.hostApi.capabilities) {
                capabilities.push(...this.hostApi.capabilities);
            }

            if (this.consumeNavigation)
                capabilities.push('navigation:intercept');

            if (this.capabilities)
                capabilities.push(...this.capabilities);

            let capMessage: PlatformCapabilitiesMessage = {
                type: 'platform_capabilities',
                build_type: 'unknown', // TODO
                bundle_identifier: 'unknown', // TODO
                platform: 'web',
                settings: {},
                version: this.version.current,
                capabilities: []
            };

            if (this.useHostCapabilities) {
                capMessage = {
                    ...capMessage,
                    ...this.hostApi.capabilitiesMessage ?? {}
                };
            }

            capMessage.capabilities = capabilities;

            this.sendMessage<PlatformCapabilitiesMessage>(capMessage);
        }

        this._messageReceived.next(message);
    };

    markIframeReady: () => void;
    iframeReady = new Promise<void>(r => this.markIframeReady = r);
}